import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import { pwrCheckout, pwr, newCheckout  } from '../../Api/pwr'

import Modal from '../../Components/Modal/index';

import './Checkout.scss';

import image from '../../images/credenciados/image.png'
import BgimageB from '../../images/credenciados/bg.svg'
import logosa from '../../images/credenciados/logo.png'
import iconTel from '../../images/credenciados/icon-tel.svg'
import iconLoc from '../../images/credenciados/icon-loc.svg'

import ArrowBlue from '../../images/icon/arrow-blue.svg'
import bgNaoGreen from '../../images/nao-pedimos/bg-green.svg'
import bgNaoGreen2 from '../../images/nao-pedimos/bg-green2.svg'
import bgNaoPontos from '../../images/nao-pedimos/bg-pontos.svg'
import bgNaoYellow from '../../images/nao-pedimos/bg-yellow.svg'
import bgImage from '../../images/sobre/image-analise.png'
import { MobileView,BrowserView } from 'react-device-detect';


export default function Index(props) {
    const [cotacao, setcotacao] = useState("")
    const [planos, setplanos] = useState("")

    useEffect(()=>{
        console.log(props.location.search)
        console.log(window.location.href)
        const query = new URLSearchParams(props.location.search);
        let id = query.get("id")
        let planos = query.get("planos")
        console.log("----",id)
        if(id){
            setcotacao(id)
            setplanos(planos)
            console.log("setou",id)
        }
    },[])
    return (
            <div id="desconto">
                <BrowserView>
                        <div id="head"></div>

                        <iframe src={"https://checkout.livesaude.com.br/checkout?planos="+planos+"&cid="+cotacao}   class="uk-height-viewport iframeMobi" frameborder="0"> </iframe>
                </BrowserView>
                <MobileView>
                     <iframe style={{"height":3300}} height='800px' src={"https://checkout.livesaude.com.br/checkout?planos="+planos+"&cid="+cotacao}   class="uk-height-viewport iframeMobi" frameborder="0"> </iframe>
                 </MobileView>

            </div>
        
    );
}
