import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

import Modal from '../../Components/Modal/index';

import './Planos.scss';

import Carousel, { Dots } from '@brainhubeu/react-carousel';

import BgBanner from '../../images/planos/super-odonto/banner-full.png'
import LogoSuper from '../../images/planos/super-odonto/logo-super.svg'

import IconNosso from '../../images/sobre/icon-nosso.svg'
import ArrowYellow from '../../images/icon/arrow-yellow.svg'
import ArrowBlue from '../../images/icon/arrow-blue.svg'
import bgNaoGreen from '../../images/nao-pedimos/bg-green.svg'
import bgNaoGreen2 from '../../images/nao-pedimos/bg-green2.svg'
import bgNaoPontos from '../../images/nao-pedimos/bg-pontos.svg'
import bgNaoYellow from '../../images/nao-pedimos/bg-yellow.svg'
import bgNaoYellowM from '../../images/nao-pedimos/bg-yellowM.svg'
import bgImage from '../../images/planos/super-odonto/bg-image.png'
     
import icon1 from '../../images/planos/super-odonto/icon-1.svg'
import icon2 from '../../images/planos/super-odonto/icon-2.svg'
import icon3 from '../../images/planos/super-odonto/icon-3.svg'
import icon4 from '../../images/planos/super-odonto/icon-4.svg'
import icon5 from '../../images/planos/super-odonto/icon-5.svg'
import icon6 from '../../images/planos/super-odonto/icon-6.svg'
import icon7 from '../../images/planos/super-odonto/icon-7.svg'
import icon8 from '../../images/planos/super-odonto/icon-8.svg'
import icon9 from '../../images/planos/super-odonto/icon-9.svg'

import ModalImage from '../../images/modal/image-modal.png'
import { BrowserView, MobileView } from 'react-device-detect';
import bgNaoGreenMobile from '../../images/nao-pedimos/bg-mobile1.svg'

import Heart from '../../images/icon/coracao-garante.svg'

export default function Index() {

    const [modalActive, setModalActive] = useState(false);
    const [modalFtActive, setModalFtActive] = useState(false);


    return (
        <div id="super-odonto">
            <div id="banner">
                <img alt="" src={BgBanner} className="animated fadeInDown" />
                <div className="content">
                    <div className="row conteudos">
                        <div className="col-12">
                            <img alt="" className="img animated fadeInLeft" src={LogoSuper} />
                            <span className="cl-gray">
                                Uma rede com mais de <b>30 mil consultórios</b> em <br />
                                todo Brasil com extensa gama de serviços para cuidar<br />
                                de <b>você e sua família.</b>
                            </span>
                        </div>
                    </div> 
                </div>
            </div>

            <div id="vantavida">
                <div className="content">
                    <div className="row">
                        <div className="subtitulo col-12" align="center">
                            <span className="cl-ligth font-32 title">O plano <b className="cl-light-blue">Super Odonto</b> oferece:</span>
                        </div>
                    </div>

                    <div className="row line">
                        <div className="image">
                            <img alt="" src={icon1} />
                        </div>
                        <div className="image">
                            <img alt="" src={icon2} />
                        </div>
                        <div className="image">
                            <img alt="" src={icon3} />
                        </div>
                        <div className="image">
                            <img alt="" src={icon4} />
                        </div>
                        <div className="image">
                            <img alt="" src={icon5} />
                        </div>
                    </div>
                    <div className="row line2">
                        <div className="image">
                            <img alt="" src={icon6} />
                        </div>
                        <div className="image">
                            <img alt="" src={icon7} />
                        </div>
                        <div className="image">
                            <img alt="" src={icon8} />
                        </div>
                        <div className="image">
                            <img alt="" src={icon9} />
                        </div>
                         
                    </div>
                </div>
                
            </div>
            <div id="banner-novo">
                <div className="content">
                    <div className="row line-full no-gutters">
                    <a href="/form-dental" className="bg-light-blue cl-white bt font-18">Cuide bem da sua saúde bucal por apenas R$ 9,90/mensal. Faça a Cotação Online <img alt="" className="icon" width="24" height="24" alt="" src={ArrowYellow} /></a>
                    </div>
                </div>

            </div>

            <BrowserView>
            <div id="solicite">
                <div className="content">
                    <div className="row">
                            <div className="col-lg-4 col-md-12 left-sl">
                                <ScrollAnimation animateOnce animateIn='fadeIn'>
                                    <img alt="" width="1087" height="880" alt="" className="bg-slgrenn" src={bgNaoGreen} />
                                </ScrollAnimation>
                                <ScrollAnimation animateOnce delay={250} animateIn='fadeIn'>
                                    <img alt="" className="bg-pontos" width="370" height="370" alt="" src={bgNaoPontos} />
                                </ScrollAnimation>
                                
                                <span className="cl-white font-32 f-weight-700">
                                <b>
                                Sua família bem cuidada, sem burocracia.
                                </b>
                                <br/>
                                Não fazemos análise de perfil.
                                </span>
                                <Link onClick={(e) => setModalFtActive(true)} className="cl-blue border-yellow bt font-18">Solicite sua cotação online <img alt="" className="icon" width="24" height="25" alt="" src={ArrowBlue} /></Link>
                            </div>

                        <div className="col-lg-8 col-md-12 left-sr left-srot">
                            <ScrollAnimation animateOnce animateIn='fadeIn'>
                                <img alt="" className="image" src={bgImage}    />
                                <img alt="" className="bg-slyellow" width="706" height="719" alt="" src={bgNaoYellow} />
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce animateIn='fadeInRight'>
                                <img alt="" className="bg-green2" width="179" height="359" alt="" src={bgNaoGreen2} />
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>
            </BrowserView>

            <MobileView>
            <div id="solicite">
                <div className="content">
                    <div className="row">

                        <div className="col-lg-8 col-md-12 left-sr left-srot">
                            <ScrollAnimation animateOnce animateIn='fadeIn'>
                                <img alt="" className="image image-sobre" src={bgImage} />
                                <img alt="" className="bg-slyellow" src={bgNaoYellowM} />
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce animateIn='fadeInRight'>
                                <img alt="" className="bg-green2" width="179" height="359" alt="" src={bgNaoGreen2} />
                            </ScrollAnimation>
                        </div>
                        <div className="col-lg-4 col-md-12 left-sl">
                                <img alt="" className="bg-green-mobile" src={bgNaoGreenMobile} />
                                <img alt="" className="bg-pontos" width="370" height="370" alt="" src={bgNaoPontos} />
                                <span className="cl-white font-64 f-weight-700">
                                    Sua Família bem cuidada,
                                    <br />
                                    sem burocracia.
                                </span>
                                <Link onClick={(e) => setModalFtActive(true)} className="cl-blue border-yellow bt font-18">Solicite sua cotação online <img alt="" className="icon" width="24" height="25" alt="" src={ArrowBlue} /></Link>
                            </div>
                    </div>
                </div>
            </div>
            </MobileView>

            <Modal modalActive={modalFtActive}>
            <div id="modal-ini" className="bg-blue">
                <button onClick={(e) => setModalFtActive(false)} className="fechar"></button>
                <img alt="" width="520" height="293" alt="" src={ModalImage} />
                <BrowserView>
                    <div className="buttons">
                        <a href="/form-auto" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Auto</a>
                        <Link to="/form-saude" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Saúde</Link>
                        <Link to="/form-casa" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Residência</Link>
                        <Link to="/form-pet" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Pet</Link>
                        <Link to="/form-dental" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Odonto</Link>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="buttons">
                        <a href="/form-auto" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Auto</a>
                        <a href="/form-saude" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Saúde</a>
                        <a href="/form-casa" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Residência</a>
                        <a href="/form-pet" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Pet</a>
                        <a href="/form-dental" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Odonto</a>
                    </div>
                </MobileView>
            </div>

            <div className='d-none' id="line-garante">
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="box-2">
                            <img alt="" width="55" height="47" alt="" src={Heart} />
                                <span className="cl-blue font-28 f-weight-700">Entenda como a Super<br /> garante seus benefícios</span>
                                <Link to="/super-garante" className="cl-blue border-blue bt">Saiba mais</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

        </div>
    );
}
