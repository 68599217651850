import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

import './Form.scss';

import swal from 'sweetalert';
import { FiLoader } from 'react-icons/fi';
import { api } from '../../Api/app'
import { pwrCheckout, pwr  } from '../../Api/pwr'

import ArrowYellow from '../../images/icon/arrow-yellow.svg'

import IconS1 from '../../images/forms/Icons-1.svg'
import IconS2 from '../../images/forms/Icons-2.svg'
import IconS3 from '../../images/forms/Icons-3.svg'
import IconS4 from '../../images/forms/Icons-4.svg'
import IconS5 from '../../images/forms/Icons-5.svg'
import IconS6 from '../../images/forms/Icons-6.svg'
import IconS7 from '../../images/forms/Icons-7.svg'
import IconS8 from '../../images/forms/Icons-8.svg'
import IconS9 from '../../images/forms/Icons-9.svg'
import Image from '../../images/forms/image-cel.svg'
import Logo from '../../images/planos/super-celular/logo-super.svg'


import InputMask from 'react-input-mask';
import { BrowserView, MobileView } from 'react-device-detect';


export default function Index() {
    const [menuPlano, setmenuPlano] = useState(false)

    const [stepLoad, setstepLoad] = useState(false)
    const [passo1, setPasso1] = useState('');
    const [passo2, setPasso2] = useState('display-none');
    const [passo3, setPasso3] = useState('display-none');
    const [nome, setNome] = useState('');
    const [email , setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [code, setCode] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [plano,  setPlano] = useState('PLANO_CELULAR');


    const [camposFormulario, setCamposFormularios] = useState({
        nome: '',
        telefone: '',
        email: '',
        mensagem: '',
    })

    
    async function insertPasso1(event)
    {
        if(event)
            event.preventDefault()
        let slsmnId = null;
        let slsmnNwId = null;
        swal({
            title: ' ',
            text: 'Enviado com sucesso, você será direcionado ao checkout para concluir sua proteção!',
            showConfirmButton: false
          })

        let response =  await pwrCheckout.post('/qttnStep1?plano='+plano,{
            h: 'sp beneficios507',
            slsmnId: slsmnId,
            slsmnNwId: 'NrP2PKql',
            name: nome,
            email: email,
            phone: telefone
        });

        setCode(response.data.back)

        setTimeout(function(){
            window.location.href = 'https://crm.superbeneficios.com/checkout?h='+response.data.back;
        })
    
    }
 
    async function insertPasso2(){
        let response  = await pwr.post('/qttnStep3',
            {code: code, h: "sp beneficios507", cty: parseInt(2419), workVehicle: false});
 
        if(response.data){
            swal("Enviado com sucesso!");
            setTimeout(function(){
                window.location.href = '/form-auto';
            },2000)
        }
        //swal("Enviado com sucesso!");
    }

    async function setPasso(passo){
        if(passo === 1 ){
            setPasso1('')
            setPasso2('display-none')
            setPasso3('display-none')
        }else if(passo === 2){
            setPasso1('display-none')
            setPasso2('')
            setPasso3('display-none')
        }else if(passo === 3){
            setPasso1('display-none')
            setPasso2('display-none')
            setPasso3('')
        }
    }
 

    return (
        <div id="credenciados">
            <div id="head"></div>
            <div id="formg">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 left-form" >
                            <div className="text">
                                <div className="breve-pet bg-yellow cl-black font-16 f-weight-700 form-dpn">
                                    <span>Em <br />
                                    breve!</span>
                                </div>
                                <BrowserView>
                                    <span className="txt font-32 cl-white">
                                        Seu aparelho seguro e <br/> prevenido de grandes <br/>gastos futuros!<br/>
                                        
                                    </span>
                                    <span className="txt font-24 cl-white">
                                        Receba todos os benefícios que a
                                            Super está preparando em primeira mão!
                                        
                                    </span>
                                </BrowserView>
                                <MobileView>
                                    <span className="txt font-32 cl-white font-weight-700">
                                    Seu aparelho seguro e <br/> prevenido de grandes <br/>gastos futuros!<br/>
                                    </span>
                                </MobileView>
                            </div>
                            <MobileView>
                                <img className="image-celular" src={Image} />
                            </MobileView>
                            <BrowserView>
                                <img className="image-celular" src={Image} />
                            </BrowserView>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <form className="col-12  form row form-gen  no-gutters" method="post" >
                                <div className="col-12 row no-gutters infos">
                                    <img width="203" height="59" alt="" src={Logo} />
                                    <p font-16> 
                                        A tranquilidade que você merece não precisa custar caro! <br></br>
                                        <b className="cl-light-blue">Não fazemos análise de Perfil.</b>
                                    </p>
                                </div>
                                
                                <div className={'col-12 row form-gen '+passo1}>


                                        <div className="col-12">
                                            <input type="text" id="pwrAssocNm" maxlength="256" value={nome} onChange={(e) => setNome(e.target.value)} placeholder="Nome" />
                                        </div>
                                        
                                        <div className="col-12">
                                            <input type="email" id="pwrAssocEml" maxlength="128" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" />
                                        </div>
                                        
                                        <div className="col-12" align="right">
                                            <input type="text" id="pwrAssocPhn" maxlength="31" value={telefone} onChange={(e) => setTelefone(e.target.value)} placeholder="Telefone" />
                                        </div>
                                        
                                        <div className="ipt-check col-12">
                                            <button type='button' id="pwr_step_1_next" onClick={() => insertPasso1()}>Próximo  Passo<img width="24" height="24" alt="" src={ArrowYellow} /></button>
                                        </div>
                                </div>

                                <div className={'col-12 row form-gen '+passo2}>
                                <div className="col-lg-6 col-md-12">
                                    <select id="pwrStt" onClick={(e) => setEstado(e.target.value)} ></select>
                                    </div>

                                    <div className="col-lg-6 col-md-12" align="right">
                                        <select id="pwrCty" onClick={(e) => setCidade(e.target.value)} data-placeholder="Selecione a cidade">
                                            <option>Cidade</option>
                                        </select>
                                    </div>


                                    <div className="ipt-check col-12">
                                        <button id="pwr_step_2" onClick={() => insertPasso2()}>Receber cota&ccedil;&atilde;o <img width="24" height="24" alt="" src={ArrowYellow}/>
                                        </button>
                                    </div>
                                </div>

                            </form>   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
