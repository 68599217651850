import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './Index.scss'

import Modal from '../../Components/Modal/index';

import BgBanner1 from '../../images/backgrounds/banner-left.svg'
import BgBanner2 from '../../images/backgrounds/banner-right.svg'

import Banner1 from '../../images/banner/banner-novo.png'

import SpGarante from '../../images/produtos/supergarante.svg'
import Prod1 from '../../images/produtos/moto.png'
import Prod2 from '../../images/produtos/cachorro.svg'
import Prod3 from '../../images/produtos/carro.png'
import Prod4 from '../../images/produtos/casa.png'
import Prod5 from '../../images/produtos/pessoa.png'
import Prod6 from '../../images/produtos/suv.svg'
import Prod7 from '../../images/produtos/odonto.svg'
import Prod8 from '../../images/produtos/carroseg.png'
import Prod9 from '../../images/produtos/celular.png'

import BgProd1 from '../../images/backgrounds/prod-1.svg'
import BgProd2 from '../../images/backgrounds/prod-2.svg'
import BGmobile from '../../images/banner/bg-mobilefull.svg'
import BGmobile2 from '../../images/banner/bg-mobilefull-d1.png'
import BGmobile1 from '../../images/banner/bg-mobilefull-e1.png'

import ArrowWhite from '../../images/icon/arrow-white.svg'
import ArrowYellow from '../../images/icon/arrow-yellow.svg'
import ArrowBlue from '../../images/icon/arrow-blue.svg'

import bgNaoGreen from '../../images/nao-pedimos/bg-green.svg'
import bgNaoGreen2 from '../../images/nao-pedimos/bg-green2.svg'
import bgNaoPontos from '../../images/nao-pedimos/bg-pontos.svg'
import bgNaoYellow from '../../images/nao-pedimos/bg-yellow.svg'
import bgNaoYellowM from '../../images/nao-pedimos/bg-yellowM.svg'
import bgImage from '../../images/nao-pedimos/imagem.png'
import bgImageM from '../../images/nao-pedimos/imagem-mobile.svg'
import bgNaoGreenMobile from '../../images/nao-pedimos/bg-mobile1.svg'

import ModalMoto from '../../images/produtos/modal/produtos.png'
import IconCar from '../../images/produtos/modal/icon-car.png'

import Heart from '../../images/icon/coracao-garante.svg'

import ModalImage from '../../images/modal/image-modal.png'
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import LazyLoad from 'react-lazyload'

export default function Index() {

    const [modalActive, setModalActive] = useState(false);

    useEffect(()=>{
         //if(false || window.localStorage.getItem('modal')){
         //   setModalActive(true)
         //   window.localStorage.setItem('modal', true)
         // }
    },[])

    const [modalFtActive, setModalFtActive] = useState(false);

    return (
        <div id="home">

            <div id="banner">
                <BrowserView>
                    <img id="bg-banner1" width="1112" height="767" alt="" className="remove_animated fadeInLeft" src={BgBanner1} />
                    <img id="bg-banner2" width="1283" height="480" alt="" className="remove_animated fadeInRight" src={BgBanner2} />
                </BrowserView>

                <MobileView>
                    <img id="bg-banner1" width="1112" height="767" alt="" className="remove_animated fadeIn" src={BgBanner1} />
                    <img id="bg-banner2" width="1283" height="480" alt="" className="remove_animated fadeIn" src={BgBanner2} />
                </MobileView>

                <MobileView>
                    {/*<img id="bg-mobile" className="remove_animated fadeIn" width="750" height="1193" alt="" src={BGmobile} />*/}
                </MobileView>
                
                <BrowserView>
                <div className="content">
                    <div className="row no-gutters">
                        <div className="col-5 texto-banner">
                            <span className="font-48 f-weight-500 cl-white">
                                <span className="cl-yellow f-weight-500">
                                    <h1>Proteção Integral <br />24h em todo Brasil!</h1>
                               </span>
                            </span>
                            <p className="font-24 cl-white">
                                Proteja o que realmente importa <br />
                                para você. Sua família, sua casa, <br />
                                seu carro, sua saúde e seu pet.<br />
                                E o melhor tudo em um só lugar. 
                            </p>
                            <a href="https://api.whatsapp.com/send?phone=551140035542" rel="noopener" target="_blank" className="bt border-white cl-white font-16">ou fale conosco no WhatsApp <img width="24" height="24" className="icon" alt="" src={ArrowWhite} /></a>
                        </div>
                        <div className="col-7">
                        <video style={{"width":700, 'height':400}} id="player" class="iframe videoiframe cr-img remove_animated fadeIn"  autoplay="autoplay" controls  width="700" heigth="400">
                            <source src="https://superbeneficios.com/luciano.mp4" type="video/mp4" />
                        </video>
                        {/* <iframe class="iframe videoiframe cr-img remove_animated fadeIn" width="700" height="400"  src="/player.html" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allow="autoplay; fullscreen" allowfullscreen></iframe> */}

                            {/*<img width="796" height="668" className="cr-img remove_animated fadeIn" src={Banner1} alt="Baixe nosso APP" />*/}
                        </div>
                    </div>
                </div>
                </BrowserView>

                <MobileView>
                <div className="col-12 texto-banner">
                        <span className="font-48 f-weight-500 cl-white">
                            <span className="cl-yellow f-weight-500">
                            <h1>Proteção Integral <br />24h em todo Brasil!</h1>
                               </span>
                            </span>
                            <p className="font-24 cl-white">
                                Proteja o que realmente <br />
                                importa para você. <br />
                                Sua família, sua casa, <br />
                                seu carro, sua saúde e <br />
                                seu pet. E o melhor  <br /> 
                                tudo em um só lugar. 
                                <br />
                                <br />
                            </p>
                            <a href="https://api.whatsapp.com/send?phone=551140035542" rel="noopener" target="_blank" className="bt border-white cl-white font-16">ou fale por WhatsApp <img className="icon" width="24" height="24" alt="" src={ArrowWhite} /></a>
                        </div>
                <div className="fundomobi">
                    <div className="row no-gutters">
	    	    </div>

                </div>
               <div>
                  {/*<iframe class="iframe videoiframe  remove_animated fadeIn" width="700" height="400"  src="/player.html" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allow="autoplay; fullscreen" allowfullscreen></iframe>*/}
                  <video  webkit-playsinline="true" playsinline="true" style={{"width":'100%', 'height':400}} id="player" class="iframe videoiframe remove_animated fadeIn"  autoplay="autoplay" controls  width="700%" heigth="400">
                            <source src="https://superbeneficios.com/luciano.mp4" type="video/mp4" />
                  </video>
               </div>
                </MobileView>


            </div>

            <div id="produtos">
                <LazyLoad>
                <div className="content">
                    <div className="row">
                        <div className="col-12 title" align="center">
                            {/*<LazyLoad animateIn='fadeIn activeprod1' offset={320} animateOnce initiallyVisible={false} >
                                <img width="259" height="172" alt="" id="bg-banner1" src={BgProd1} />
                            </LazyLoad>*/}
                            {/*<LazyLoad  animateIn='fadeIn activeprod2' offset={320} animateOnce initiallyVisible={false} >
                                <img width="248" height="248" alt="" id="bg-banner2" src={BgProd2} />
                        </LazyLoad>*/}
                            <h2 className="cl-black font-48">Você quer benefícios?</h2>
                            <span className="bg-yellow cl-black bt font-22"><b>Atendemos 24 horas</b> em todo Brasil</span>
                        </div>
                        <div className="col-12 row no-gutters">
                            <LazyLoad  className="col-lg-4 col-md-12" animateIn='fadeIn'>
                                <a href="/form-auto"  className="box">
                                    <img width="281" height="263" alt="" src={Prod1}></img>
                                    <span className="produto cl-gray font-28">Super Auto | <span className="cl-blue font-28">Motos</span></span>
                                    <span className="cl-gray font-16 font-p-mobal1">Planos a partir de</span>
                                    <span className="cl-blue font-32 f-weight-700 font-p-mobal">R$ 39,90<span className="cl-gray font-16 f-weight-400">/mensais</span></span>
                                    <a href="/form-auto" className="bg-light-blue cl-white bt font-18">Cotação Online <img className="icon" width="24" height="24" alt="" src={ArrowYellow} /></a>
                                    <MobileView>
                                        <div className="line-bottom bg-green">
                                            <p className="cl-blue font-32"><span className="cl-yellow">+</span> <b>Benefícios</b> Super Auto</p>
                                        </div>
                                    </MobileView>
                                </a>
                            </LazyLoad>

                            <LazyLoad className="col-lg-4 col-md-12" animateIn='fadeIn'>
                                <a href="/form-auto" className="box">
                                    <img width="291" height="243" alt="" src={Prod3} />
                                    <span className="produto cl-gray font-28">Super Auto | <span className="cl-blue font-28">Carros</span></span>
                                    <span className="cl-gray font-16 font-p-mobal1">Planos a partir de</span>
                                    <span className="cl-blue font-32 f-weight-700 font-p-mobal">R$ 39,90<span className="cl-gray font-16 f-weight-400">/mensais</span></span>
                                    <a href="/form-auto" className="bg-light-blue cl-white bt font-18">Cotação Online <img className="icon" width="24" height="24" alt="" src={ArrowYellow} /></a>
                                    <MobileView>
                                        <div className="line-bottom bg-green">
                                            <p className="cl-blue font-32"><span className="cl-yellow">+</span> <b>Benefícios</b> Super Auto</p>
                                        </div>
                                    </MobileView>
                                </a>
                            </LazyLoad>

                            {/* <LazyLoad className="col-lg-4 col-md-12" animateIn='fadeIn'>
                                <a href="/form-auto" className="box box-blue">
                                    <div className="bg-yellow" align="center">
                                        <span className="cl-black font-20 f-wheight-700">Novidade!</span>
                                    </div>
                                    <span className="cl-blue font-42 f-wheight-700 tit">
                                        SUPER COMBO <br/>
                                        <span className="cl-light-blue font-34">Auto + Saúde</span>
                                    </span>
                                    <p className="cl-blue font-16">
                                        • Benefícios Super Auto<br/>
                                        <b><span className="cl-yellow">+</span> Rede completa com descontos<br/> em consultas, exames, vacinas e mais<br/>
                                        <span className="cl-yellow">+</span> Vídeoconsulta com médico<br/> 24 horas por dia, 7 dias na semana</b>
                                    </p>
                                    <span className="cl-blue font-16 font-p-mobal1">Por apenas</span>
                                    <span className="cl-blue font-32 f-weight-700 font-p-mobal">+R$ 1,99<span className="cl-blue font-16 f-weight-400">/mensais</span></span>
                                    <a href="/form-auto" className="bg-light-blue cl-white bt font-18">Cotação Online <img className="icon" width="24" height="24" alt="" src={ArrowYellow} /></a>
                                </a>
                            </LazyLoad> */}

                            <LazyLoad className="col-lg-4 col-md-12" animateIn='fadeIn'>
                                <a href="/form-auto" className="box">
                                    <img width="322" height="137" alt="" src={Prod8}></img>
                                    <span className="produto pdspc cl-gray font-28">Super Auto | <span className="cl-blue font-28">Segurança</span></span>
                                    <span className=" cl-gray font-28 sub-tit">Furto e Roubo<br/>+ Assistência 24h</span>
                                    <span className="cl-light-blue font-16 font-p-mobal1 tb-fipe">100% da tabela FIPE até R$ 15.000</span>
                                    <span className="cl-blue font-32 f-weight-700 font-p-mobal">R$ 54,90<span className="cl-gray font-16 f-weight-400">/mensais</span></span>
                                    <a href="/form-auto" className="bg-light-blue cl-white bt font-18">Cotação Online <img className="icon" width="24" height="24" alt="" src={ArrowYellow} /></a>
                                    <MobileView>
                                        <div className="line-bottom bg-green">
                                            <p className="cl-blue font-32"><span className="cl-yellow">+</span> <b>Benefícios</b> Super Auto</p>
                                        </div>
                                    </MobileView>
                                </a>
                            </LazyLoad>

                            <LazyLoad className="col-lg-4 col-md-12" animateIn='fadeIn'>
                                <Link to="super-residencia" className="box  line-box">
                                    <img width="280" height="264" alt="" src={Prod4}></img>
                                    <span className="produto cl-gray font-28">Super <span className="cl-blue font-28">Residência</span></span>
                                    <span className="cl-gray font-18 txt-pc font-p-mobal1" align="center">Seu lar merece segurança<br />
e muitas vantagens!</span>
                                    <Link  to="super-residencia" className="bg-light-blue cl-white bt font-18">Saiba mais <img className="icon" width="24" height="24" alt="" src={ArrowYellow} /></Link>
                                 </Link>
                            </LazyLoad>
                            

                            

                            <LazyLoad className="col-lg-4 col-md-12" animateIn='fadeIn'>
                                <Link to="super-dental" className="box  line-box">
                                    <img width="263" height="262" alt="" src={Prod7}></img>
                                    <span className="produto cl-gray font-28">Super <span className="cl-blue font-28">Odonto</span></span>
                                    <span className="cl-gray font-18 txt-pc font-p-mobal1" align="center">serviços para cuidar de <br />
                                    você e sua família!</span>
                                    <Link  to="super-dental" className="bg-light-blue cl-white bt font-18">Saiba mais <img className="icon" width="24" height="24" alt="" src={ArrowYellow} /></Link>
                                 </Link>
                            </LazyLoad>


                            {/* <LazyLoad className="col-lg-4 col-md-12" animateIn='fadeIn'>
                                <a href="/super-celular" className="box">
                                    <img width="244" height="248" alt="" src={Prod9}></img>
                                    <span className="produto cl-gray font-28">Super <span className="cl-blue font-28">Celular</span></span>
                                    <span className="cl-gray font-16 font-p-mobal1">Planos a partir de</span>
                                    <span className="cl-blue font-32 f-weight-700 font-p-mobal">R$ 9,90<span className="cl-gray font-16 f-weight-400">/mensais</span></span>
                                    <Link to="super-celular" className="bg-light-blue cl-white bt font-18">Cotação Online <img className="icon" width="24" height="24" alt="" src={ArrowYellow} /></Link>
                                    
                                </a>
                            </LazyLoad> */}
                            <LazyLoad className="col-lg-4 col-md-12" animateIn='fadeIn'>
                                <a href="/super-vida" className="box">
                                    <img width="240" height="257" alt="" src={Prod6}></img>
                                    <span className="produto cl-gray font-28">Super <span className="cl-blue font-28">Saúde</span></span>
                                    <span className="cl-gray font-16 font-p-mobal1">Planos a partir de</span>
                                    <span className="cl-blue font-32 f-weight-700 font-p-mobal">R$ 29,90<span className="cl-gray font-16 f-weight-400">/mensais</span></span>
                                    <Link to="super-vida" className="bg-light-blue cl-white bt font-18">Cotação Online <img className="icon" width="24" height="24" alt="" src={ArrowYellow} /></Link>
                                    <MobileView>
                                        <div className="line-bottom bg-green">
                                            <p className="cl-blue font-32"><span className="cl-yellow">+</span> <b>Benefícios</b> Super Saúde</p>
                                        </div>
                                    </MobileView>
                                </a>
                            </LazyLoad>

                            <LazyLoad className="col-lg-4 col-md-12" animateIn='fadeIn'>
                                <Link to="super-pet" className="box  line-box">
                                    <img width="263" height="289" alt="" src={Prod2} className="img-pet"></img>
                                    <span className="produto cl-gray font-28">Super <span className="cl-blue font-28">Pet</span></span>
                                    <span className="cl-gray font-18 txt-pc font-p-mobal1" align="center">Seu amigo merece os<br />
melhores benefícios!</span>
                                    <Link  to="super-pet" className="bg-light-blue cl-white bt font-18">Saiba mais <img className="icon" width="24" height="24" alt="" src={ArrowYellow} /></Link>
                                </Link>
                            </LazyLoad>

                            
                        </div>
                    </div>
                </div>
                </LazyLoad>
            </div>
            <div className='d-none'  id="banner-novo">
                <LazyLoad>
                    <div className="content">
                     <div className="row line-full line-bg  ">
                         
                        <div className="col-lg-4 col-offset-2 col-md-12 titles">
                            <img width="312" height="154" alt="" src={SpGarante} ></img>
                        </div>
                        <div className="col-lg-7 col-md-12">
                            <div className='row'>
                                <span class="cl-light-blue font-32 f-weight-700">A Super <span className="cl-blue font-32">GARANTE</span> seus benefícios!</span>
                            </div>
                            <div className='row'>
                                <div className='col-md-10 text-center col-offset-2 cl-blue font-7'>
                                     
                                    Saiba como o FGRS (Fundo Garantidor contra Riscos Sistêmicos) traz total segurança para os associados da Super Benefícios.!
                                   &nbsp; <a class="bg-blue cl-white bt font-18" href="super-garante"> Acesse <img className="icon" width="24" height="24" alt="" src={ArrowYellow} /></a> 
                                </div>
                            </div>
                        </div>
                         
                    </div>
                </div>
                </LazyLoad>
            </div>

            <BrowserView>
            <div id="solicite">
                <LazyLoad>
                <div className="content">
                    <div className="row">
                        
                            <div className="col-lg-4 col-md-12 left-sl">
                                
                                <LazyLoad animateOnce animateIn='fadeIn'>
                                    <img width="1087" height="880" alt="" className="bg-slgrenn" src={bgNaoGreen} />
                                </LazyLoad>
                                <LazyLoad animateOnce delay={250} animateIn='fadeIn'>
                                    <img className="bg-pontos" width="370" height="370" alt="" src={bgNaoPontos} />
                                </LazyLoad>
                                
                                <span className="cl-white font-64 f-weight-700">
                                    Não
                                    pedimos
                                    análise<br />
                                    de perfil.
                                </span>
                                <Link onClick={(e) => setModalFtActive(true)} className="cl-blue border-yellow bt font-18">Solicite sua cotação online <img className="icon" width="24" height="25" alt="" src={ArrowBlue} /></Link>
                            </div>

                        <div className="col-lg-8 col-md-12 left-sr">
                            <LazyLoad animateOnce animateIn='fadeIn'>
                                <img className="image" width="885" height="869" alt="" src={bgImage} />
                                <img className="bg-slyellow" width="706" height="719" alt="" src={bgNaoYellow} />
                            </LazyLoad>
                            <LazyLoad animateOnce animateIn='fadeInRight'>
                                <img className="bg-green2" width="179" height="359" alt="" src={bgNaoGreen2} />
                            </LazyLoad>
                        </div>

                    </div>
                </div>
                </LazyLoad>
            </div>
            </BrowserView>

            <MobileView>
            <div id="solicite">
                <div className="content">
                    <div className="row">

                        <div className="col-lg-8 col-md-12 left-sr">
                                <img className="image" width="300" height="400" alt="" src={bgImageM} />
                                <img className="bg-slyellow" width="273" height="504" alt="" src={bgNaoYellowM} />
                                <img className="bg-green2" width="179" height="359" alt="" src={bgNaoGreen2} />
                        </div>

                        
                        <div className="col-lg-4 col-md-12 left-sl">
                                <img className="bg-green-mobile" width="750" height="744" alt="" src={bgNaoGreenMobile} />
                                <img className="bg-pontos" width="370" height="370" alt="" src={bgNaoPontos} />
                                <span className="cl-white font-64 f-weight-700">
                                    Não
                                    pedimos<br />
                                    análise
                                    de perfil.
                                </span>
                                <Link onClick={(e) => setModalFtActive(true)} className="cl-blue border-yellow bt font-18">Solicite sua cotação<img className="icon" width="24" height="25" alt="" src={ArrowBlue} /></Link>
                        </div>
                        
                    </div>
                </div>
            </div>
            </MobileView>



            <Modal modalActive={modalActive}>
                <div id="modal-ini" className="bg-blue">
                    <button onClick={(e) => setModalActive(false)} className="fechar"></button>
                    <img width="520" height="293" alt="" src={ModalImage} />
                    <BrowserView>
                    <div className="buttons">
                        <a href="/form-auto" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Auto</a>
                        <Link to="/form-saude" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Saúde</Link>
                        <Link to="/form-casa" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Residência</Link>
                        <Link to="/form-pet" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Pet</Link>
                        <Link to="/form-dental" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Odonto</Link>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="buttons">
                        <a href="/form-auto" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Auto</a>
                        <a href="/form-saude" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Saúde</a>
                        <a href="/form-casa" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Residência</a>
                        <a href="/form-pet" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Pet</a>
                        <a href="/form-dental" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Odonto</a>
                    </div>
                </MobileView>
                </div>

                <div className='d-none' id="line-garante">
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="box-2">
                            <img width="55" height="47" alt="" src={Heart} />
                                <span className="cl-blue font-28 f-weight-700">Entenda como a Super<br /> garante seus benefícios</span>
                                <Link to="/super-garante" className="cl-blue border-blue bt">Saiba mais</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            </Modal>
            
            <Modal modalActive={modalFtActive}>
            <div id="modal-ini" className="bg-blue">
                <button onClick={(e) => setModalFtActive(false)} className="fechar"></button>
                <img width="520" height="293" alt="" src={ModalImage} />
                <BrowserView>
                    <div className="buttons">
                        <a href="/form-auto" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Auto</a>
                        <Link to="/form-saude" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Saúde</Link>
                        <Link to="/form-casa" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Residência</Link>
                        <Link to="/form-pet" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Pet</Link>
                        <Link to="/form-dental" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Odonto</Link>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="buttons">
                        <a href="/form-auto" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Auto</a>
                        <a href="/form-saude" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Saúde</a>
                        <a href="/form-casa" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Residência</a>
                        <a href="/form-pet" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Pet</a>
                        <a href="/form-dental" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Odonto</a>
                    </div>
                </MobileView>

            </div>

                            
            <div className='d-none' id="line-garante">
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="box-2">
                            <img width="55" height="47" alt="" src={Heart} />
                                <span className="cl-blue font-28 f-weight-700">Entenda como a Super<br /> garante seus benefícios</span>
                                <Link to="/super-garante" className="cl-blue border-blue bt">Saiba mais</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

        </div>
    );
}
