import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

import Modal from '../../Components/Modal/index';

import './Planos.scss';

import Carousel, { Dots } from '@brainhubeu/react-carousel';

import BgBanner from '../../images/planos/super-pet/banner-full.png'
import LogoSuper from '../../images/planos/super-pet/logo-super.svg'

import BulletMais from '../../images/planos/super-pet/icon-plus3.svg'
import BulletMais1 from '../../images/planos/super-pet/icon-plus1.svg'
import BulletMais2 from '../../images/planos/super-pet/icon-plus2.svg'

import PlanoEssencial from '../../images/planos/super-pet/super-essencial.svg'
import PlanoMais from '../../images/planos/super-pet/super-mais.svg'
import PlanoPremium from '../../images/planos/super-pet/super-premium.svg'

import Familia from '../../images/planos/super-residencia/familia.png'
import Cachorro1 from '../../images/planos/super-pet/cachorro1.png'
import PointsBene from '../../images/planos/super-vida/points-bene.svg'

import IconNosso from '../../images/sobre/icon-nosso.svg'
import ArrowYellow from '../../images/icon/arrow-yellow.svg'

import ImageQualidade from '../../images/planos/super-vida/image-qualidade.png'
import ImageQualidadeMobile from '../../images/planos/super-vida/image-qualidade-mobile.png'

import ImageSuperAuto from '../../images/planos/super-vida/image-super-auto.svg'
import LogoSuperAuto from  '../../images/planos/super-vida/logo-super-auto.svg'
import Logo from '../../images/forms/logo-pet.svg'

import ImageLink1 from '../../images/planos/super-vida/box-link1.png'
import ImageLink2 from '../../images/planos/super-vida/box-link2.png'

import ArrowBlue from '../../images/icon/arrow-blue.svg'
import bgNaoGreen from '../../images/nao-pedimos/bg-green.svg'
import bgNaoGreen2 from '../../images/nao-pedimos/bg-green2.svg'
import bgNaoPontos from '../../images/nao-pedimos/bg-pontos.svg'
import bgNaoYellow from '../../images/nao-pedimos/bg-yellow.svg'
import bgNaoYellowM from '../../images/nao-pedimos/bg-yellowM.svg'
import bgImage from '../../images/planos/super-pet/image-analise.png'

import Heart from '../../images/icon/coracao-garante.svg'

import icon1 from '../../images/planos/super-pet/icon1.svg'
import icon2 from '../../images/planos/super-pet/icon2.svg'
import icon3 from '../../images/planos/super-pet/icon3.svg'
import icon4 from '../../images/planos/super-pet/icon4.svg'
import icon5 from '../../images/planos/super-pet/icon5.svg'
import icon6 from '../../images/planos/super-pet/icon6.svg'
import icon7 from '../../images/planos/super-pet/icon7.svg'
import icon8 from '../../images/planos/super-pet/icon8.svg'
import icon9 from '../../images/planos/super-pet/icon9.svg'
import icon10 from '../../images/planos/super-pet/icon10.svg'
import icon11 from '../../images/planos/super-pet/icon11.svg'
import icon12 from '../../images/planos/super-pet/icon12.svg'
import icon13 from '../../images/planos/super-pet/icon13.svg'
import icon14 from '../../images/planos/super-pet/icon14.svg'


import ModalImage from '../../images/modal/image-modal.png'
import { BrowserView, MobileView } from 'react-device-detect';
import bgNaoGreenMobile from '../../images/nao-pedimos/bg-mobile1.svg'

export default function Index() {

    const [modalActive, setModalActive] = useState(false);
    const [modalFtActive, setModalFtActive] = useState(false);


    return (
        <div id="super-pet">

            <BrowserView>
            <div id="banner">
            
                <img alt="" src={BgBanner} className="animated fadeInDown" />
                <div className="content">
                    <div className="row conteudos">
                        <div className="col-12">
                            <img alt="" className="img animated fadeInLeft" src={LogoSuper} />
                            <span className="cl-gray">
                            Vida longa e saudável para 
                                <b>o seu melhor amigo?</b><br />
                                Com nossos planos Super Pet você garante toda o cuidado e carinho que um membro da família merece. 
                            </span>
                        </div>
                    </div> 
                </div>
            </div></BrowserView>
            <MobileView>
            <div id="banner">
            
            <div className="content">
                <div className="row conteudos">
                    <div className="col-12">
                        <img alt="" className="img animated fadeInLeft" src={LogoSuper} />
                        <span className="cl-gray">
                        Vida longa e saudável para 
                            <b>o seu melhor amigo?</b><br />
                            Com nossos planos Super Pet você garante toda o cuidado e carinho que um membro da família merece. 
                        </span>
                    </div>
                </div> 
            </div>
        </div>
            </MobileView>

            <div id="vantavida">
                
                <div className="content">
                    <div className="row">
                        <div className="subtitulo col-12" align="">
                            <span className="cl-ligth font-32 title">São muitos benefícios!<br></br>Temos planos para todas as necessidades</span>
                        </div>
                    </div>

                    <div className="row line">
                        <div className="image">
                            <img alt="" src={icon1} />
                        </div>
                        <div className="image">
                            <img alt="" src={icon2} />
                        </div>
                        <div className="image">
                            <img alt="" src={icon3} />
                        </div>
                        <div className="image">
                            <img alt="" src={icon4} />
                        </div>
                    </div>
                    <div className="row line">
                        <div className="image">
                            <img alt="" src={icon5} />
                        </div>

                        <div className="image">
                            <img alt="" src={icon6} />
                        </div>
                        <div className="image">
                            <img alt="" src={icon7} />
                        </div>
                        <div className="image">
                            <img alt="" src={icon8} />
                        </div>
                         
                    </div>
                    <div className="row line">
                        <div className="image">
                            <img alt="" src={icon9} />
                        </div>
                        <div className="image">
                            <img alt="" src={icon10} />
                        </div>

                        <div className="image">
                            <img alt="" src={icon11} />
                        </div>
                        <div className="image">
                            <img alt="" src={icon12} />
                        </div>
                        
                         
                    </div>
                    <div className="row line2">
                         <div className="image">
                            <img alt="" src={icon13} />
                        </div>
                        <div className="image">
                            <img alt="" src={icon14} />
                        </div>

                    </div>
                </div>
                
            </div>
            




        <div id="planos">
            <div className="content">
                <div className="row">

                    <div className="col-lg-4 col-md-12">
                        <div className="box bx1">
                            <div className="topo bg-p-blue1">
                                <img alt="" src={PlanoEssencial} />
                            </div>
                            <div className="list">
                            <span className="cl-gray"><img alt="" src={BulletMais1} />Consulta Veterinária</span>
                            <span className="cl-gray"><img alt="" src={BulletMais1} />Consulta Veterinária Emergencial</span>
                            <span className="cl-gray"><img alt="" src={BulletMais1} />Transporte (Ida e Volta)</span>
                            <span className="cl-gray"><img alt="" src={BulletMais1} />Hospedagem</span>
                            <span className="cl-gray"><img alt="" src={BulletMais1} />Agendamento de Consultas</span>
                            <span className="cl-gray"><img alt="" src={BulletMais1} />Envio de Ração</span>
                            <span className="cl-gray"><img alt="" src={BulletMais1} />Estabelecimentos Relacionados (Concierge Veterinário)</span>
                            </div>
                            <div className="footer">
                                <p className="font-14 cl-gray f-wheight-700">Seu Pet feliz por apenas</p>
                                <span className="cl-blue font-32 f-wheight-700 valor">R$ 39,90<span className="font-16 cl-gray f-wheight-500">/mês</span></span>
                                <Link to="form-pet?plano=1" className="bt f-wheight-600  bg-p-blue1 cl-white font-18">Cotação Online <img alt="" width="24" height="24" alt="" src={ArrowYellow} /></Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <div className="box bx2">
                            <div className="topo bg-p-blue2">
                                <img alt="" src={PlanoMais} />
                            </div>
                            <div className="list">
                            <span className="cl-gray"><img alt="" src={BulletMais2} />Consulta Veterinária</span>
                            <span className="cl-gray"><img alt="" src={BulletMais2} />Consulta Veterinária Emergencial</span>
                            <span className="cl-gray"><img alt="" src={BulletMais2} />Transporte (Ida e Volta)</span>
                            <span className="cl-gray"><img alt="" src={BulletMais2} />Hospedagem</span>
                            <span className="cl-gray"><img alt="" src={BulletMais2} />Agendamento de Consultas</span>
                            <span className="cl-gray"><img alt="" src={BulletMais2} />Envio de Ração</span>
                            <span className="cl-gray"><img alt="" src={BulletMais2} />Estabelecimentos Relacionados (Concierge Veterinário)</span>
                            <span className="cl-gray"><img alt="" src={BulletMais2} />Exames Laboratoriais</span>
                            <span className="cl-gray"><img alt="" src={BulletMais2} />Exames de Imagem</span>
                            <span className="cl-gray"><img alt="" src={BulletMais2} />Agendamento de Leva e Traz</span>
                            <span className="cl-gray"><img alt="" src={BulletMais2} />Leva e Traz</span>
                            </div>
                            <div className="footer">
                                <p className="font-14 cl-gray f-wheight-700">Tranquilidade para o seu amigo por:</p>
                                <span className="cl-blue font-32 f-wheight-700 valor">R$ 79,90<span className=" font-16 cl-gray f-wheight-500">/mês</span></span>
                                <Link to="form-pet?plano=2" className="bt f-wheight-600  bg-p-blue2 cl-white font-18">Cotação Online <img alt="" width="24" height="24" alt="" src={ArrowYellow} /></Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <div className="box last-box bx3">
                            <div className="topo bg-p-blue3">
                                <img alt="" src={PlanoPremium} />
                            </div>
                            <div className="list">
                            <span className="cl-gray"><img alt="" src={BulletMais} />Consulta Veterinária</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Consulta Veterinária Emergencial</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Hospedagem em Caso de Viagem **</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Agendamento de Consultas</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Envio de Ração</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Informações Sobre Vacinas</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Estabelecimentos Relacionados (Concierge Veterinário)</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Consulta com Especialista</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Cirurgia (Exceto Estética e Parto)</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Hospedagem em Caso de Viagem **</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Aplicação de Vacinas (Antirrábica, 
Polivalente e Bodetella)</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Internação</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Castração</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Exames Laboratoriais</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Exames de Imagem</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Implantação de Chip</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Orientação Veterinária por Telefone</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Leva e Traz</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Assistência Funeral</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Limpeza de Cálculos Dentais</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Transporte em Caso de Localização do PET</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Acupuntura e Fisioterapia</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Transporte do PET ao Veterinário</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Transporte para o Hotel</span>
                            <span className="cl-gray"><img alt="" src={BulletMais} />Hospedagem em Caso de Doença</span>

                            </div>
                            <div className="footer">
                                <p className="font-14 cl-gray f-wheight-700">Tratamento VIP para seu bichinho por:</p>
                                <span className="cl-blue font-32 f-wheight-700 valor">R$ 199,90<span className="font-16 cl-gray f-wheight-500">/mês</span></span>
                                <Link to="form-pet?plano=3" className="bt f-wheight-600  bg-p-blue3 cl-white font-18">Cotação Online <img alt="" width="24" height="24" alt="" src={ArrowYellow} /></Link>
                            </div>
                        </div>
                    </div>
                    <BrowserView>
                        <img alt="" className="familia-residencia" src={Cachorro1} />
                    </BrowserView>
                </div>
            </div>
        </div>

        
        <BrowserView>
            <div id="solicite">
                <div className="content">
                    <div className="row">
                            <div className="col-lg-4 col-md-12 left-sl">
                                <ScrollAnimation animateOnce animateIn='fadeIn'>
                                    <img alt="" className="bg-slgrenn" width="1087" height="880" alt=""  src={bgNaoGreen} />
                                </ScrollAnimation>
                                <ScrollAnimation animateOnce delay={250} animateIn='fadeIn'>
                                    <img alt="" className="bg-pontos" width="370" height="370" alt=""  src={bgNaoPontos} />
                                </ScrollAnimation>
                                
                                <span className="cl-white font-32 f-weight-700">
                                <b>
                                Seu melhor amigo, mais saudável e feliz!
                                </b>
                                <br/>
                                Não fazemos análise de perfil.
                                </span>
                                <Link onClick={(e) => setModalFtActive(true)} className="cl-blue border-yellow bt font-18">Solicite sua cotação online <img alt="" className="icon" width="24" height="25" alt="" src={ArrowBlue} /></Link>
                            </div>

                        <div className="col-lg-8 col-md-12 left-sr left-srot">
                            <ScrollAnimation animateOnce animateIn='fadeIn'>
                                <img alt="" className="image" src={bgImage}    />
                                <img alt="" className="bg-slyellow" width="706" height="719" alt="" src={bgNaoYellow} />
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce animateIn='fadeInRight'>
                                <img alt="" className="bg-green2" width="179" height="359" alt="" src={bgNaoGreen2} />
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>
            </BrowserView>

            <MobileView>
            <div id="solicite">
                <div className="content">
                    <div className="row">

                        <div className="col-lg-8 col-md-12 left-sr left-srot">
                            <ScrollAnimation animateOnce animateIn='fadeIn'>
                                <img alt="" className="image image-sobre" src={bgImage} />
                                <img alt="" className="bg-slyellow" src={bgNaoYellowM} />
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce animateIn='fadeInRight'>
                                <img alt="" className="bg-green2" width="179" height="359" alt="" src={bgNaoGreen2} />
                            </ScrollAnimation>
                        </div>
                        <div className="col-lg-4 col-md-12 left-sl">
                                <img className="bg-green-mobile" width="750" height="744" alt="" src={bgNaoGreenMobile} />
                                <img className="bg-pontos" width="370" height="370" alt="" src={bgNaoPontos} />
                                <img alt="" className="bg-green-mobile" src={bgNaoGreenMobile} />
                                <img alt="" className="bg-pontos" width="370" height="370" alt="" src={bgNaoPontos} />
                                <span className="cl-white font-64 f-weight-700">
                                    Set PET <br/>bem cuidado,
                                    <br />
                                    sem burocracia.
                                </span>
                                <Link onClick={(e) => setModalFtActive(true)} className="cl-blue border-yellow bt font-18">Solicite sua cotação online <img alt="" className="icon" width="24" height="25" alt="" src={ArrowBlue} /></Link>
                            </div>
                    </div>
                </div>
            </div>
            </MobileView>



            <Modal modalActive={modalFtActive}>
            <div id="modal-ini" className="bg-blue">
                <button onClick={(e) => setModalFtActive(false)} className="fechar"></button>
                <img alt="" width="520" height="293" alt="" src={ModalImage} />
                <BrowserView>
                    <div className="buttons">
                        <a href="/form-auto" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Auto</a>
                        <Link to="/form-saude" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Saúde</Link>
                        <Link to="/form-casa" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Residência</Link>
                        <Link to="/form-pet" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Pet</Link>
                        <Link to="/form-dental" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Odonto</Link>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="buttons">
                        <a href="/form-auto" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Auto</a>
                        <a href="/form-saude" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Saúde</a>
                        <a href="/form-casa" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Residência</a>
                        <a href="/form-pet" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Pet</a>
                        <a href="/form-dental" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Odonto</a>
                    </div>
                </MobileView>
            </div>

            <div className='d-none' id="line-garante">
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="box-2">
                            <img alt="" width="55" height="47" alt="" src={Heart} />
                                <span className="cl-blue font-28 f-weight-700">Entenda como a Super<br /> garante seus benefícios</span>
                                <Link to="/super-garante" className="cl-blue border-blue bt">Saiba mais</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        </div>
    );
}
