import React, { useState, useEffect, useHistory } from 'react';
import { Link } from 'react-router-dom';
import { pwr } from '../../Api/pwr'
import ScrollAnimation from 'react-animate-on-scroll';

import {Helmet} from "react-helmet";

import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import './Form.scss';
import { FiLoader } from 'react-icons/fi';

import ArrowYellow from '../../images/icon/arrow-yellow.svg'
import axios from "axios"

import IconA1 from '../../images/forms/Icona-1.svg'
import IconA2 from '../../images/forms/Icona-2.svg'
import IconA3 from '../../images/forms/Icona-3.svg'
import IconA4 from '../../images/forms/Icona-4.svg'
import IconA5 from '../../images/forms/Icona-5.svg'
import IconA6 from '../../images/forms/Icona-6.svg'
import IconA7 from '../../images/forms/Icona-7.svg'
import IconA8 from '../../images/forms/Icona-8.svg'
import IconA9 from '../../images/forms/Icona-9.svg'
import IconA10 from '../../images/forms/Icona-10.svg'
import IconA11 from '../../images/forms/Icona-11.svg'
import IconA12 from '../../images/forms/Icona-12.svg'
import ImageAuto from '../../images/forms/image-auto.png'
import LogoAuto from '../../images/forms/logo-auto.svg'
import {api} from "../../Api/app";
import swal from "sweetalert";


export default function Index(props) {
    const [history, setHistory] = useState(useHistory);
    const [passo1, setPasso1] = useState('');
    const [passo2, setPasso2] = useState('display-none');
    const [passo3, setPasso3] = useState('display-none');

    const [nome, setNome] = useState('');
    const [email , setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [tipo, setTipo] = useState('');
    const [placa, setPlaca] = useState('');
    const [marca, setMarca] = useState('');
    const [anoModelo, setAnoModelo] = useState('');
    const [modelo, setModelo] = useState('');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [code, setCode] = useState('');
    const [carregando, setCarregando] = useState(false);
    const [identificadorcotacao,setIdentificador] = useState('')


    const dadosPasso1 = useState({})
    useEffect(()=>{
        window.loadScript("/jquery.min.js")
        setIdentificador((Math.random() + 1).toString(36).substring(2))
        const query = new URLSearchParams(props.location.search);
        const origem = query.get("origin")
        let _id = query.get("id")
        if(origem)
            localStorage.setItem('origem', origem);
        else
            console.log("nao ha origem")
        if(_id)
            localStorage.setItem('consultor_id', _id);
        else
            console.log("Nao ha consultor")
    },[])
    async function salvaLog(){
        capturarCotacao()
    	let url = 'https://superbeneficios.com/cotacao/save.php?phone='+telefone+"&email=&name="+nome+" --"+window.location.href+"|ref:"+document.referrer;
    	const response_origin = await axios.get(url)
    	console.log(response_origin)
    }
    async function capturarCotacao(){
        const query = new URLSearchParams(props.location.search);
        const origem = query.get("origin") || localStorage.getItem("origem")
        let _id = query.get("id") || localStorage.getItem("consultor")
        axios.post('https://southamerica-east1-live-saude1.cloudfunctions.net/cotacao-v2backup', 
            {'id_unico':identificadorcotacao,'url':window.location.href+"|"+document.referrer,'origem':origem,'consultor':_id,'nome':nome,'email':email,'telefone': telefone})
    }
    async function insertPasso1(){
        let slsmnId = null;
        let slsmnNwId = null;
        const query = new URLSearchParams(props.location.search);
        let _id = query.get("id")
        if(_id && _id!=undefined && _id!=null){
            slsmnNwId=_id
        }
        setCarregando(true)

        /* cria a cotacao na pagina reserva */
        let response =  await pwr.post('/qttnStep1',{
            h: 'sp beneficios507',
            slsmnId: slsmnId,
            slsmnNwId: slsmnNwId,
            name: nome,
            email: email,
            phone: telefone
        });
        axios.post('https://southamerica-east1-live-saude1.cloudfunctions.net/cotacao-v2backup', 
            {'id_unico':identificadorcotacao,'card_back_power':response.data.back})

        const origem = query.get("origin")
        if(origem){
            axios.get("https://us-central1-wholesale-sms.cloudfunctions.net/quotation-powercrm?pg=/v2/set_origin_by_back&name="+nome+"&back=" +response.data.back+"&origin_new_id="+origem)
        }
        window.PowerCRM.init([211,'CODIGO-DO-CLIENTE'])
        setCode(response.data.back)
        setPasso(2);

    }
	
    async function insertPasso2(){
        let response  = await pwr.post('/qttnStep2',{
            code: code,
            h: 'sp beneficios507',
            vhclTyp: tipo,
            vhclModel: modelo,
            //pwrVhclPlts: placa,
            //pwrVhclVrsn:anoModelo,
            //pwrVhclMdl:modelo,
            cty: cidade,
            //pwrStt:estado,
            vhclYear: anoModelo,
        });
        axios.post('https://southamerica-east1-live-saude1.cloudfunctions.net/cotacao-v2backup', 
            {'id_unico':identificadorcotacao,'modelo_veiculo':modelo,'ano_veiculo': anoModelo, 'tipo_veiculo': tipo})

        if(response.data){
            //swal("Enviado com sucesso!w");
            setTimeout(function(){
                window.location.href = 'https://app.powercrm.com.br/newQuotation?h='+code;
            },2000)
        }

        //swal("Enviado com sucesso!");
    }

    async function setPasso(passo){
        if(passo === 1 ){
            setPasso1('')
            setPasso2('display-none')
            setPasso3('display-none')
        }else if(passo === 2){
            setPasso1('display-none')
            setPasso2('')
            setPasso3('display-none')
        }else if(passo === 3){
            setPasso1('display-none')
            setPasso2('display-none')
            setPasso3('')
        }
    }

    return (

        <div id="credenciados">

            <div id="head"></div>
            <div id="formg">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 left-form" align="center">
                        {/* <iframe class="iframe videoiframeauto cr-img remove_animated fadeIn" width="600" height="450" src="/player.html" frameborder="0" allow="autoplay; fullscreen"></iframe> */}
                        <video  webkit-playsinline="true"  playsinline="true" style={{"width":'100%', 'height':400}} id="player" class="iframe remove_animated fadeIn"  autoplay="autoplay" controls  width="700%" heigth="400">
                            <source src="https://superbeneficios.com/luciano.mp4" type="video/mp4" />
                        </video>
 
                            {/*<div className="carousel">
                            <Carousel                      
                            arrows
                            infinite
                            centered 
                            >
                                <img src={IconA1} />
                                <img src={IconA2} />
                                <img src={IconA3} />
                                <img src={IconA4} />
                                <img src={IconA5} />
                                <img src={IconA6} />
                                <img src={IconA7} />
                                <img src={IconA8} />
                                <img src={IconA9} />
                                <img src={IconA10} />
                                <img src={IconA11} />
                                <img src={IconA12} />
                            </Carousel>
                            </div>
                            <Link to="super-auto" className="bt border-white font-16 cl-white">Veja mais informações</Link>
                            <img className="image-auto" src={ImageAuto} />
                            */}
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="box-form row no-gutters form animated fadeIn">
                                <div className="col-12 row no-gutters infos">
                                    <img src={LogoAuto} />
                                    <p font-16> 
                                        Temos vários planos para você ficar protegido e tranquilo.<br />
                                        <b className="cl-light-blue">Não fazemos análise de Perfil.</b>
                                    </p>
                                    <div className="box-bl bg-blue font-16 cl-yellow">
                                        + Receba benefícios Super Saúde com seu Super Auto! Simule e confira!
                                    </div>
                                </div>

                                <div className={'col-12 row form-auto '+passo1}>


                                        <div className="col-12">
                                            <input type="text" id="pwrAssocNm" maxlength="256" value={nome} onChange={(e) => setNome(e.target.value)}  onBlur={(e)=> capturarCotacao()}  placeholder="Nome" />
                                        </div>
                                        
                                        <div className="col-12">
                                            <input type="email" id="pwrAssocEml" maxlength="128" value={email} onChange={(e) => setEmail(e.target.value)}  onBlur={(e)=> capturarCotacao()}  placeholder="E-mail" />
                                        </div>
                                        
                                        <div className="col-12" align="right">
                                            <input type="text" id="pwrAssocPhn" maxlength="31" value={telefone}   onChange={(e) => setTelefone(e.target.value)} onBlur={(e)=> salvaLog()} placeholder="Telefone" />
                                        </div>
                                        
                                        <div className="ipt-check col-12">
                                            <button id="pwr_step_1_next"  disabled={carregando} onClick={() => insertPasso1()}  >  {carregando == true ?  <FiLoader /> : 'Próximo  Passo'} <img width="24" height="24" alt="" src={ArrowYellow} /></button>
                                        </div>
                                </div>

                                <div className={'col-12 row form-auto '+passo2}>
                                    <div className="col-12">
                                        <select id="pwrVhclTp" onChange={(e) => setTipo(e.target.value)} ></select>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <input type="text" onChange={(e) => setPlaca(e.target.value)} maxLength="7" id="pwrVhclPlts" placeholder="Placa"/>
                                    </div>

                                    <div className="col-lg-6 col-md-12" align="right">
                                        <select onChange={(e) => setPlaca(e.target.value)}  id="pwrVhclBrnd">
                                            <option>Marca</option>
                                        </select>
                                    </div>

                                    <div className="col-lg-6 col-md-12">
                                        <select onChange={(e)=> setAnoModelo(e.target.value)} id="pwrVhclVrsn">
                                            <option>Ano Modelo</option>
                                        </select>
                                    </div>

                                    <div className="col-lg-6 col-md-12" align="right">
                                        <select onChange={(e)=> setModelo(e.target.value)} id="pwrVhclMdl">
                                            <option>Modelo</option>
                                        </select>
                                    </div>

                                    <div className="col-lg-6 col-md-12">
                                        <select id="pwrStt" onClick={(e) => setEstado(e.target.value)} ></select>
                                    </div>

                                    <div className="col-lg-6 col-md-12" align="right">
                                        <select id="pwrCty" onClick={(e) => setCidade(e.target.value)} data-placeholder="Selecione a cidade">
                                            <option>Cidade</option>
                                        </select>
                                    </div>

                                    <div className="ipt-check col-12">
                                        <input type="checkbox" name="" id="pwrWorkVhcl"/> <span>Taxi ou Uber?</span>
                                    </div>

                                    <div className="ipt-check col-12">
                                        <button id="pwr_step_2" onClick={() => insertPasso2()}>Receber cota&ccedil;&atilde;o <img width="24" height="24" alt="" src={ArrowYellow}/>
                                        </button>
                                    </div>
                                </div>

                            </div>   
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
