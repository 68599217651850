import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import BulletMais from '../../images/planos/super-pet/icon-plus3.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/fontawesome-free-solid'

import './Form.scss';

import swal from 'sweetalert';
import { FiLoader } from 'react-icons/fi';

import { api, checkout } from '../../Api/app'
import { pwrCheckout, pwr, newCheckout } from '../../Api/pwr'


import ArrowYellow from '../../images/icon/arrow-yellow.svg'

import IconS1 from '../../images/forms/Icons-1.svg'
import IconS2 from '../../images/forms/Icons-2.svg'
import IconS3 from '../../images/forms/Icons-3.svg'
import IconS4 from '../../images/forms/Icons-4.svg'
import IconS5 from '../../images/forms/Icons-5.svg'
import IconS6 from '../../images/forms/Icons-6.svg'
import IconS7 from '../../images/forms/Icons-7.svg'
import IconS8 from '../../images/forms/Icons-8.svg'
import IconS9 from '../../images/forms/Icons-9.svg'
import Image from '../../images/forms/image-pet.png'
import Logo from '../../images/forms/logo-pet.svg'
import ModalImage from '../../images/modal/image-modal.png'

import ImagePetMobile from '../../images/forms/image-pet-mobile.png'

import InputMask from 'react-input-mask';
import { BrowserView, MobileView } from 'react-device-detect';


export default function Index(props) {

    const [stepLoad, setstepLoad] = useState(false)
    const [menuPlano, setmenuPlano] = useState("")
    const [listaAssinaturas, setlistaAssinaturas] = useState([])

    const [camposFormulario, setCamposFormularios] = useState({
        nome: '',
        telefone: '',
        email: '',
        mensagem: '',
        plano: '',
    })

    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        let plano_url = query.get("plano")
        addPet = addPet.bind(this)
        if (plano_url) {
            let opcoes = {
                '1': 'PLANO_PETBASICO',
                '2': 'PLANO_PETPLUS',
                '3': 'PLANO_PETPREMIUM'
            }
            if (opcoes[plano_url]){
                setmenuPlano(opcoes[plano_url])
                listaAssinaturas.push({'plano':opcoes[plano_url],'nome':''})
                return;
            }
        }       
        listaAssinaturas.push({'plano':menuPlano||'PLANO_PETBASICO','nome':''})

    }, [])
    async function excluirItem(ele,i ){
        var lista = listaAssinaturas.slice(); // Make a copy of the emails first.
        lista.splice(i,1);
        if(lista.length == 0){
            lista=[{'plano':menuPlano||'PLANO_PETBASICO','nome':''}];
        }
        setlistaAssinaturas(lista); // Update the state.
    
    } 
    async function addPet(){
        let curr = listaAssinaturas;
        const uniqueID = {'plano':menuPlano,'nome':''}
        let r = await setlistaAssinaturas([...curr, uniqueID]);
    }
    function handleNomeListChange(index,event){
        var lista = listaAssinaturas.slice(); // Make a copy of the emails first.
        lista[index]['nome'] = event.target.value; // Update it with the modified email.
        setlistaAssinaturas(lista); // Update the state.
    
    }
    function handlePlanoListChange(index, event){
        var lista = listaAssinaturas.slice(); // Make a copy of the emails first.
        lista[index]['plano'] = event.target.value; // Update it with the modified email.
        setlistaAssinaturas(lista); // Update the state.
    
    }
    async function insertPasso1(event) {
        if (event)
            event.preventDefault()
        console.log(menuPlano)
        // if ("Plano" == menuPlano) {
        //     swal({
        //         title: ' ',
        //         text: 'Selecione seu plano',
        //         showCancelButton: false,
        //         showConfirmButton: false
        //     })
        //     return;
        // }

        let slsmnId = null;
        let slsmnNwId = null;
        // swal({
        //     title: ' ',
        //     text: 'Enviado com sucesso, você será direcionado ao checkout para concluir sua proteção!',
        //     showCancelButton: false,
        //     showConfirmButton: false
        //   })

        // let response =  await pwrCheckout.post('/qttnStep1?plano='+menuPlano,{
        //     h: 'sp beneficios507',
        //     slsmnId: slsmnId,
        //     slsmnNwId: 'NrP2PKql',
        //     name: camposFormulario.nome,
        //     email: camposFormulario.email,
        //     phone: camposFormulario.telefone
        // });


        // window.location.href = 'https://crm.superbeneficios.com/checkout?h='+response.data.back;

        setstepLoad(true)


        let response = await newCheckout.post('quotation?pg=v2/create', {
            h: 'sp beneficios507',
            slsmnId: slsmnId,
            slsmnNwId: 'NrP2PKql',
            name: camposFormulario.nome,
            email: camposFormulario.email,
            phone: camposFormulario.telefone,
            planos: listaAssinaturas
        });


        window.location.href = '/checkout?id=' + (response.data.id||response.data.back)+'&planos='+btoa(JSON.stringify(listaAssinaturas));

    }



    async function handleSend(event) {

        event.preventDefault()

        var data = camposFormulario

        setstepLoad(true)

        var response = await api.post('/form-pet', data)

        if (response.data.error == true) {
            alert(response.data.message)
        } else {
            swal("Enviado com sucesso!");
        }

        setTimeout(function () {
            setstepLoad(false)
        })



        console.log(response)

    }

    function changeInputMult(e) {

        var meusdados = camposFormulario
        meusdados[e.target.name] = e.target.value
        setCamposFormularios({ ...meusdados })

        console.log(meusdados)

    }

    return (
        <div id="credenciados">
            <div id="head"></div>
            <div id="formg">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 left-form" >
                            <div className="text">
                                <div className="breve-pet bg-yellow cl-black font-16 f-weight-700 form-dpn">
                                    <span>Em <br />
                                        breve!</span>
                                </div>
                                <BrowserView>
                                    <span className="txt font-32 cl-white">
                                        Todos os
                                        membros da
                                        família<br />
                                        merece o melhor!<br />
                                        <b className="font-weight-700">Os de 4 patas também!</b>
                                    </span>
                                </BrowserView>
                                <MobileView>
                                    <span className="txt font-32 cl-white font-weight-700">
                                        Todos os
                                        membros da<br />
                                        família merece
                                        o melhor!<br />
                                        <b>Os de 4 patas
                                            também!</b>
                                    </span>
                                </MobileView>
                            </div>
                            <MobileView>
                                <img className="image-pet-mobile" src={ImagePetMobile} />
                            </MobileView>
                            <BrowserView>
                                <img className="image-pet" src={Image} />
                            </BrowserView>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="box-form row no-gutters form animated fadeIn">
                                <div className="col-12 row no-gutters infos">
                                    <img width="203" height="59" alt="" src={Logo} />
                                    <p font-16>
                                        A qualidade de vida que sua família merece não precisa custar caro!<br />
                                        <b className="cl-light-blue">Não fazemos análise de Perfil.</b>
                                    </p>
                                </div>
                                <div className="col-12 row no-gutters">
                                    <form className="col-12  row form-gen  no-gutters" onSubmit={insertPasso1} method="post" >
                                        <div className='row col-12' id='planos'>

                                            {listaAssinaturas.map((item, i) => (
                                            <div key={i} className='row' id='plano'>
                                                <div className='col-6'>
                                                    <select name="plano" required value={item.plano}  onChange={(e)=>{handlePlanoListChange(i, e)}} >
                                                        <option disabled >Plano</option>
                                                        <option value="PLANO_PETBASICO">Super PET Essencial | 39,90/mensais</option>
                                                        <option value="PLANO_PETPLUS">Super PET + | 79,90/mensais</option>
                                                        <option value="PLANO_PETPREMIUM">Super PET Premium | 199,90/mensais</option>
                                                    </select>
                                                </div>
                                                <div className={"col-5 cxListaPlanoDir"}>
                                                    <input type="text" required value={item.nome} onChange={(e)=>{handleNomeListChange(i, e)}} placeholder="Nome do seu Pet" />
                                                </div>
                                                <div className={"col-1 cxListaPlanoDir"}>
                                                    <FontAwesomeIcon onClick={(e)=>{excluirItem(e, i)}} icon={faTimes} />

                                                </div>
                                            </div>
                                            ))}
                                            <div className={"col-8 mb-2 ipt-check cxListaPlanoDir"}>
                                                <a onClick={(e)=>addPet()} class="btNovoPet cl-blue bt border-blue font-10" href='javascript:;'><img width="16" height="16" alt="" rel="noopener" src={BulletMais} /> Adicionar PET</a>
                                            </div>
                                            {/*repete*/}
                                            
                                            
                                            {/*para de repetir*/}
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <div className="col-12">
                                            <input type="text" required value={camposFormulario.nome} onChange={(e) => changeInputMult(e)} name="nome" placeholder="Nome completo" />
                                        </div>

                                        <div className="col-12">
                                            <InputMask mask="(99) 99999-9999" required type="text" value={camposFormulario.telefone} onChange={(e) => changeInputMult(e)} name="telefone" placeholder="Telefone" />
                                        </div>

                                        <div className="col-12" align="right">
                                            <input type="text" required name="email" value={camposFormulario.email} onChange={(e) => changeInputMult(e)} placeholder="E-mail" />
                                        </div>



                                        <div className="ipt-check col-12">
                                            <button type="submit">{stepLoad === true ? <FiLoader /> : "Contratar!"} <img width="24" height="24" alt="" src={ArrowYellow} /> </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
